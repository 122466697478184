import Define from '../constants/define'
import {
  Attendu,
  AttenduInterface,
  SingleFileResponseInterface,
  TotalAccessoriesApi,
  ExportAttenduType,
  ExportResponseInterface,
  AttenduDeleteInterface,
  AttenduArrayInterface,
} from '../models'
import baseAxios from './clientAPI'
import { getUrlFilterParams } from '../utils'
import { PrintOptionsType } from '../components/Common/Popover/Print/PrintOptions'

export type AttenduCreatePayload = {
  body: Attendu
}

const attenduApi = {
  createAttendu({ body }: AttenduCreatePayload): Promise<AttenduInterface> {
    const uri = '/warehouse/attendu/'
    return baseAxios.post(uri, { attendu: body })
  },

  getAllAttendu(payload?: any): Promise<AttenduArrayInterface> {
    const url = '/warehouse/attendu/all'
    const { condition, pageSize, pageIndex } = getUrlFilterParams()
    const params = {
      limit: payload?.limit || pageSize,
      offset: payload?.offset || (pageIndex - 1) * pageSize || 0,
      user_id: localStorage.getItem(Define.USER_ID),
      role: localStorage.getItem(Define.ROLE),
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      ...condition,
      ...payload,
    }
    return baseAxios.get(url, { params })
  },

  getTotalAttendu(payload: {}): Promise<TotalAccessoriesApi> {
    const url = '/warehouse/accessories/get-total'
    const params = {
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      type: 'attendu',
    }
    return baseAxios.get(url, { params })
  },

  getAttenduById(id: string): Promise<AttenduInterface> {
    const url = `/warehouse/attendu/${id}`
    return baseAxios.get(url)
  },

  editAttendu(body: Attendu): Promise<string> {
    const url = '/warehouse/attendu/'
    return baseAxios.put(url, { attendu: body })
  },

  deleteAttenduById(id: string): Promise<AttenduDeleteInterface> {
    const url = `/warehouse/attendu/${id}?user_id=${
      localStorage.getItem(Define.USER_ID) || ''
    }`
    return baseAxios.delete(url)
  },

  printInforAtt(
    attProId: string,
    stockId?: string,
    options?: PrintOptionsType
  ): Promise<SingleFileResponseInterface> {
    const url = '/warehouse/attendu/print-infor-att'
    return baseAxios.get(url, {
      params: {
        att_production_id: attProId,
        stock_id: stockId,
        ...options,
      },
    })
  },

  printAllSscc(
    attProIds: string[],
    options: PrintOptionsType
  ): Promise<SingleFileResponseInterface> {
    const url = `/warehouse/attendu/gen-all-sscc`
    const body = { att_production_ids: attProIds, ...options }
    return baseAxios.post(url, body)
  },

  fixReserve(data: { id: string; is_block: boolean }) {
    const url = `/warehouse/reserve/${data.id}`
    return baseAxios.put(url, { entry: data })
  },

  updateReservesData(data: Array<{ id: string; comment: string }>) {
    const url = '/warehouse/reserve'
    return baseAxios.put(url, { entry: data })
  },

  exportAllAttendu(data: ExportAttenduType): Promise<ExportResponseInterface> {
    const url = '/warehouse/attendu/export'
    return baseAxios.post(url, data)
  },

  checkBeforeDeleteAttendu(attenduId: string): Promise<AttenduInterface> {
    const url = `/warehouse/attendu/get-delete-status?id=${attenduId}`
    return baseAxios.get(url)
  },

  sendMasMission(data: { mas_mission_id: string }) {
    const url = '/warehouse/mission/send-mass-mission'
    return baseAxios.patch(url, data)
  },
}

export default attenduApi
