import { t } from 'i18next'
import { Link } from 'react-router-dom'
import { AppNotiEvent, NotiEvent } from '../../enum/NotificationType'
import { Trans } from 'react-i18next'
import { NotificationBell } from '../../models/Notification'
import { extractCodeAndNom } from './func'
import NotiItem from './NotiItem'
import { ClientCompanyWarehouseData } from '../../models'
import { DataType } from './NotificationScreen'
import commandeApi from '../../http/commandeApi'
import { showErrorNotification } from '../../utils/notification'
import { Dispatch, SetStateAction } from 'react'
import missionApi from '../../http/missionApi'
import { AttenduMissionType } from '../../enum/AttenduMissionType'
import attenduApi from '../../http/attenduApi'
const baseURL = process.env.REACT_APP_S_URL

enum ExportStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}
type ExportMessage = {
  object?: string
  link_download?: string
  status?: ExportStatus
}
type RapMissionMessage = {
  object?: string
  commande_no?: string
  commande_id?: string
}
type MultipleBlMessage = {
  commande_id?: string
  message?: string
}
type MailImportMessage = {
  file_name?: string
  file_path?: string
  metadata?: string
  class_name?: string
  is_hyperlink?: boolean
  link_download?: string
}
const mapElement = (element?: string) => {
  switch (element) {
    case 'attendu':
      return 'Attendu'
    case 'stock':
      return 'Stock'
    case 'commande':
      return 'Commande'

    default:
      return ''
  }
}

function getDownloadLink(restLink: string) {
  const baseURL = process.env.REACT_APP_API_ENDPOINT
  return `${baseURL}/files/common/export-data/${restLink}`
}

function convertSuccessMessage(message: ExportMessage) {
  const patternSuccessMessage = t('noti.message.exportSuccess')
  const messageSplit = patternSuccessMessage.split('%space')
  return (
    <span>
      <span>{messageSplit[0]}</span>&nbsp;
      <span>{mapElement(message.object)}</span>&nbsp;
      <span>{messageSplit[1]}</span>&nbsp;
      <span>
        <a href={getDownloadLink(message.link_download || '')} target="_blank">
          {t('noti.message.clickHere')}
        </a>
      </span>
      &nbsp;
      <span>{messageSplit[2]}</span>
    </span>
  )
}

function convertFailureMessage(message: ExportMessage) {
  const patternFailureMessage = t('noti.message.exportFailure')
  return (
    <span>
      {patternFailureMessage.replace('%s', mapElement(message.object))}
    </span>
  )
}

export function convertToExportMessage(json: string) {
  try {
    const messageObj = JSON.parse(json) as ExportMessage
    if (messageObj.status === ExportStatus.SUCCESS) {
      return convertSuccessMessage(messageObj)
    }
    return convertFailureMessage(messageObj)
  } catch (error) {
    return ''
  }
}

export function convertToRapMissionMessage(json: string) {
  try {
    const messageObject = JSON.parse(json) as RapMissionMessage
    const messageSplit = t('noti.message.rapMission').split('%s')
    const url = `/gestion-de-stock/commande/commande-edit/${messageObject.commande_id}?page-index=1&page-size=25&must-search=false`
    return (
      <span>
        <span>{messageSplit[0]}</span>
        <span>{messageObject.commande_no}</span>
        <span>{messageSplit[1]}</span>
        <a href={url} target="_blank">
          {t('noti.message.clickHereFirstUpper')}
        </a>
        <span>{messageSplit[2]}</span>
      </span>
    )
  } catch (error) {
    return ''
  }
}

export function convertDratMultipleBlMessage(json: string) {
  try {
    const messageObject = JSON.parse(json) as MultipleBlMessage
    const { commande_id, message = '' } = messageObject

    const regex = new RegExp(t('noti.message.draftMultipleBlPattern'))
    const match = message.match(regex)
    if (!match) {
      return message
    }

    const blNom = match[1]
    const commandeNom = match[2]
    const missionLoadingDraft = match[3]

    const messageSplit = t('noti.message.draftMultipleBlPattern')
      .replaceAll('(.+?)', '%s')
      .replace('(.+)', '%s')
      .split('%s')

    const url = `/gestion-de-stock/commande/commande-envoyer-en-chargement/${commande_id}?page-index=1&page-size=25&count=1`
    return (
      <span>
        <span>{messageSplit[0]}</span>
        <span>{blNom}</span>
        <span>{messageSplit[1]}</span>
        <a href={url} target="_blank">
          {commandeNom}
        </a>
        <span>{messageSplit[2]}</span>
        <span>{missionLoadingDraft}</span>
      </span>
    )
  } catch (error) {
    return ''
  }
}

export function convertMultipleBlMessage(
  json: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) {
  try {
    const messageObject = JSON.parse(json) as MultipleBlMessage
    const { commande_id, message = '' } = messageObject

    const regex = new RegExp(t('noti.message.multipleBlPattern'))
    const match = message.match(regex)
    if (!match) {
      return message
    }

    const commandeNom = match[1]
    const missionLoadingDraft = match[2]
    const messageSplit = t('noti.message.multipleBlPattern')
      .replaceAll('(.+?)', '%s')
      .replace('(.+)', '%s')
      .split('%s')
    const url = `/gestion-de-stock/commande/commande-envoyer-en-chargement/${commande_id}?page-index=1&page-size=25&count=1`

    const onClick = () => {
      setLoading(true)
      missionApi
        .getAllMission({
          nom: missionLoadingDraft,
        })
        .then((res) => {
          const missionId = res?.data?.entry[0].id
          const path = `${baseURL}/gestion-de-stock/mission/mission-detail?page-index=1&page-size=25&viewMissionWithId=${missionId}`
          window.open(path, '_blank')
        })
        .catch((error) => {
          console.log(error)
          showErrorNotification()
        })
        .finally(() => {
          setLoading(false)
        })
    }

    return (
      <span>
        <span>{messageSplit[0]}</span>
        <span>{commandeNom}</span>
        <span>{messageSplit[1]}</span>
        <span>{messageSplit[2]}</span>
        <span
          onClick={onClick}
          className="cursor-pointer font-bold text-secondary"
        >
          {missionLoadingDraft}
        </span>
      </span>
    )
  } catch (error) {
    console.log('error')

    return ''
  }
}

export function capitalizeFirstLetter(str: string) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }
}

export function getDownloadLinkNotify(
  linkDownload: string = '',
  isHyperlink: boolean = false,
  className: string = 'export-data'
) {
  const baseURL = process.env.REACT_APP_API_ENDPOINT
  if (isHyperlink) {
    return linkDownload
  }
  return `${baseURL}/files/common/${className}/${linkDownload}`
}

function replaceCharacterErrors(errors: string[]): string[] {
  return errors.map((item) => {
    const find = item.indexOf(';;')
    if (find !== -1) {
      return item.replace(';;', ';')
    }
    return item
  })
}

export function convertToEmailImportMessage(
  json: string,
  event: string,
  element: string
) {
  try {
    const messageObject = JSON.parse(json) as MailImportMessage
    const nameEle = capitalizeFirstLetter(element)
    const renderMessage = (i18nKey: string) => (
      <Trans
        i18nKey={i18nKey}
        values={{ filename: messageObject.file_name, element: nameEle }}
        components={{
          customlink: (
            <Link
              to={
                getDownloadLinkNotify(
                  messageObject.link_download,
                  messageObject.is_hyperlink,
                  messageObject.class_name
                ) || ''
              }
            />
          ),
        }}
      />
    )

    switch (event) {
      case NotiEvent.FTPDataExportSuccess:
        return renderMessage(t('messageAddNewFTPExportConfigSuccessfully'))
      case NotiEvent.FTPDataExportFailed:
        return t('messageCannotAddNewFTPExportConfig')
      case NotiEvent.DataBridgeErrorFileHashExists:
        return renderMessage(t('messagedDataBridgeErrorFileHashExists'))
      case NotiEvent.MailDataImportSuccess:
      case NotiEvent.FTPDataImportSuccess:
        return renderMessage(t('mailImportSuccess'))
      case NotiEvent.MailDataImportFailed:
      case NotiEvent.FTPDataImportFailed:
        if (messageObject.metadata) {
          const parsedErrorDetail = JSON.parse(messageObject.metadata)
          const errors = parsedErrorDetail.errors
            ? parsedErrorDetail.errors
            : []
          const updatedErrors = replaceCharacterErrors(errors)

          return (
            <div>
              {renderMessage(t('mailImportFailed'))}
              {updatedErrors.length > 0 &&
                updatedErrors.map((item: string) => <p>{item}</p>)}
            </div>
          )
        }
        break
      default:
        break
    }
  } catch (error) {
    return ''
  }
}

export const mapNotiTooltip = (
  tooltips: NotificationBell[],
  onNotiClick: (
    client_id: string,
    company_id: string,
    warehouse_id: string,
    client_code_nom: string,
    company_code_nom: string,
    warehouse_code_nom: string
  ) => void
) =>
  tooltips.map((tooltip, idx) => {
    const {
      client_id,
      company_id,
      warehouse_id,
      client_code_nom = '',
      company_code_nom = '',
      warehouse_code_nom = '',
      count = 0,
    } = tooltip
    const [codeClient, nomClient] = extractCodeAndNom(client_code_nom)
    const [codeCompany, nomCompany] = extractCodeAndNom(company_code_nom)
    const [codeWarehouse, nomWarehouse] = extractCodeAndNom(warehouse_code_nom)

    return {
      key: `${idx}`,
      label: (
        <NotiItem
          codeClient={codeClient}
          nomClient={nomClient}
          codeCompany={codeCompany}
          nomCompany={nomCompany}
          codeWarehouse={codeWarehouse}
          nomWarehouse={nomWarehouse}
          onNotiClick={() =>
            onNotiClick(
              client_id,
              company_id,
              warehouse_id,
              client_code_nom,
              company_code_nom,
              warehouse_code_nom
            )
          }
          count={count}
        />
      ),
    }
  })

export const mapOthersTooltip = (
  tooltips: NotificationBell[],
  allStandards: ClientCompanyWarehouseData[],
  onNotiClick: (
    client_id: string,
    company_id: string,
    warehouse_id: string,
    client_code_nom: string,
    company_code_nom: string,
    warehouse_code_nom: string
  ) => void
) => {
  const notiSet = new Set(
    tooltips.map((tooltip) => {
      const { client_id, company_id, warehouse_id } = tooltip
      return `${client_id}-${company_id}-${warehouse_id}`
    })
  )
  const others = allStandards.filter((item) => {
    const { client_id, company_id, warehouse_id } = item
    return !notiSet.has(`${client_id}-${company_id}-${warehouse_id}`)
  })
  return others.map((tooltip, idx) => {
    const {
      client_id,
      client_code,
      client_nom,
      company_id,
      company_code,
      company_nom,
      warehouse_id,
      warehouse_code,
      warehouse_nom,
    } = tooltip

    return {
      key: `${idx}`,
      label: (
        <NotiItem
          codeClient={client_code}
          nomClient={client_nom}
          codeCompany={company_code}
          nomCompany={company_nom}
          codeWarehouse={warehouse_code}
          nomWarehouse={warehouse_nom}
          onNotiClick={() =>
            onNotiClick(
              client_id,
              company_id,
              warehouse_id,
              `${client_code}_${client_nom}`,
              `${company_code}_${company_nom}`,
              `${warehouse_code}_${warehouse_nom}`
            )
          }
        />
      ),
    }
  })
}

export const convertMasMissionMessage = (
  message: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  const masMissionNom = getMasMissionNom(message)
  if (!masMissionNom) {
    return message
  }
  const parts = t('noti.message.masMissionPattern').split('(.+?)')
  const onClick = getMasMissionOnClick(masMissionNom, setLoading)

  return (
    <div>
      <span>{parts[0]}</span>
      <span
        onClick={onClick}
        className="cursor-pointer font-bold text-secondary"
      >
        {masMissionNom}
      </span>
      <span>{parts[1]}</span>
    </div>
  )
}

export const convertPendingMissionMessage = (
  message: string,
  number: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  const index = message.indexOf(number)
  if (index === -1) {
    return message
  }
  const before = message.substring(0, index)
  const after = message.substring(index + number.length)
  const onClick = getPendingMissionOnClick(number, setLoading)
  return (
    <div>
      {before}
      <span
        onClick={onClick}
        className="cursor-pointer font-bold text-secondary"
      >
        {number}
      </span>
      {after}
    </div>
  )
}

export const convertAppRapMissionMessage = (
  message: string,
  number: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  const index = message.indexOf(number)
  if (index === -1) {
    return message
  }
  const before = message.substring(0, index)
  const after = message.substring(index + number.length)
  const onClick = getRapMissionOnClick(number, setLoading)

  return (
    <div>
      {before}
      <span
        onClick={onClick}
        className="cursor-pointer font-bold text-secondary"
      >
        {number}
      </span>
      {after}
    </div>
  )
}

export const renderAppNotiMessage = (
  message: string,
  record: DataType,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  const { number, event } = record
  if (!number) {
    return message
  }
  switch (event) {
    case AppNotiEvent.Rap:
      return convertAppRapMissionMessage(message, number, setLoading)
    case AppNotiEvent.MultiBl:
      const regex = new RegExp(t('noti.message.draftMultipleBlPattern'))
      const match = message.match(regex)
      if (match) {
        return convertDratMultipleBlMessage(message)
      }
      return convertMultipleBlMessage(message, setLoading)
    case AppNotiEvent.PendingMission:
      return convertPendingMissionMessage(message, number, setLoading)
    case AppNotiEvent.Mas:
      return convertMasMissionMessage(message, setLoading)
    default:
      return message
  }
}

export const renderWMSNotiMessage = (message: any, record: any) => {
  const event = record.event
  switch (event) {
    case NotiEvent.ExportAttendu:
    case NotiEvent.ExportCommande:
    case NotiEvent.ExportStock:
      return convertToExportMessage(message)
    case NotiEvent.RapMission:
      return convertToRapMissionMessage(message)

    case NotiEvent.MailDataImportSuccess:
    case NotiEvent.MailDataImportFailed:
    case NotiEvent.FTPDataImportSuccess:
    case NotiEvent.FTPDataImportFailed:
    case NotiEvent.DataBridgeErrorFileHashExists:
    case NotiEvent.FTPDataExportSuccess:
    case NotiEvent.FTPDataExportFailed:
      return convertToEmailImportMessage(message, event, record.element)
    case NotiEvent.AlertDluo:
    case NotiEvent.PrintingJobChange:
      return <span>{message}</span>
    default:
      return <span></span>
  }
}

const getRapMissionOnClick = (
  number: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  return () => {
    setLoading(true)
    commandeApi
      .getAllCommande({
        nom: number,
      })
      .then((res) => {
        const commandeId = res?.data?.entry[0]?.id
        const path = `${baseURL}/gestion-de-stock/commande/commande-edit/${commandeId}?page-index=1&page-size=25`
        window.open(path, '_blank')
      })
      .catch((error) => {
        console.log(error)
        showErrorNotification()
      })
      .finally(() => {
        setLoading(false)
      })
  }
}

const getAttenduOnClick = (
  number: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  return () => {
    setLoading(true)
    attenduApi
      .getAllAttendu({
        bl: number,
      })
      .then((res) => {
        const attenduId = res?.data?.entry[0]?.id
        const path = `${baseURL}/gestion-de-stock/attendu/attendu-on-mission/${attenduId}?page-index=1&page-size=25`
        window.open(path, '_blank')
      })
      .catch((error) => {
        console.log(error)
        showErrorNotification()
      })
      .finally(() => {
        setLoading(false)
      })
  }
}

const getMasMissionNom = (message: string) => {
  const regex = new RegExp(t('noti.message.masMissionPattern'))
  const match = message.match(regex)
  return match ? match[1] : ''
}

const getMasMissionOnClick = (
  masMissionNom: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  return () => {
    setLoading(true)
    missionApi
      .getAllMission({
        nom: masMissionNom,
      })
      .then((res) => {
        const missions = res?.data?.entry
        const mission =
          missions.find(
            (mission) =>
              mission.mission_type === AttenduMissionType.Mise_au_stock
          ) || missions[0]
        const missionId = mission.id
        const path = `${baseURL}/gestion-de-stock/mission/mission-detail?page-index=1&page-size=25&viewMissionWithId=${missionId}`
        window.open(path, '_blank')
      })
      .catch((error) => {
        console.log(error)
        showErrorNotification()
      })
      .finally(() => {
        setLoading(false)
      })
  }
}

const getPendingMissionOnClick = (
  missionNom: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  return () => {
    setLoading(true)
    missionApi
      .getAllMission({
        nom: missionNom,
      })
      .then((res) => {
        const missionId = res?.data?.entry[0].id
        const path = `${baseURL}/gestion-de-stock/mission/mission-detail?page-index=1&page-size=25&viewMissionWithId=${missionId}`
        window.open(path, '_blank')
      })
      .catch((error) => {
        console.log(error)
        showErrorNotification()
      })
      .finally(() => {
        setLoading(false)
      })
  }
}

const getNotiOnclick = (
  event: AppNotiEvent,
  number: string,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  switch (event) {
    case AppNotiEvent.Rap:
      return getRapMissionOnClick(number, setLoading)
    case AppNotiEvent.Mas:
      return getAttenduOnClick(number, setLoading)
    case AppNotiEvent.MultiBl:
      return getRapMissionOnClick(number, setLoading)
    case AppNotiEvent.PendingMission:
      return getPendingMissionOnClick(number, setLoading)
    default:
      return () => {}
  }
}

export const renderNotiNom = (
  number: string,
  record: DataType,
  setLoading: Dispatch<SetStateAction<boolean>>
) => {
  const onClick = getNotiOnclick(
    record.event as AppNotiEvent,
    number,
    setLoading
  )

  return (
    <span
      className="text-secondary font-semibold cursor-pointer"
      onClick={onClick}
    >
      {number}
    </span>
  )
}
