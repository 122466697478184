import Define from '../constants/define'
import { getUrlFilterParams } from '../utils'
import {
  MissionInterface,
  MissionDetailType,
  MissionUserType,
  ResponseGenerator,
  MissionUtilisateurArrayInterface,
  MissionTypeArrayInterface,
  MissionEtatArrayInterface,
  MissionDeleteInterface,
  MissionTotalInterface,
  MissionDetailPayloadInterface,
  MissionCommentPayloadInterface,
  MissionAssigneeResponse,
} from '../models'
import baseAxios from './clientAPI'

interface MissionResponse extends ResponseGenerator {
  data: {
    mission: MissionDetailType
    usr: MissionUserType
  }
}

const missionApi = {
  getMissionDetail(id: string): Promise<MissionResponse> {
    const url = `/warehouse/mission/${id}/detail-by-id`
    return baseAxios.get(url)
  },

  getMissionAssignee(missionType: number): Promise<MissionAssigneeResponse> {
    const url = '/user/assignee-user'
    const params = {
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      mission_type: missionType,
    }
    return baseAxios.get(url, { params })
  },

  getMissionType(): Promise<MissionTypeArrayInterface> {
    const url = '/warehouse/mission/list-type'
    return baseAxios.get(url)
  },

  getMissionEtat(): Promise<MissionEtatArrayInterface> {
    const url = '/warehouse/mission/list-progress'
    return baseAxios.get(url)
  },

  getUtilisateur(payload: {
    client_id: string
    warehouse_id: string
    company_id: string
  }): Promise<MissionUtilisateurArrayInterface> {
    const url = '/warehouse/mission/list-ultilsateur'
    const params = {
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
    }
    return baseAxios.get(url, { params })
  },

  getTotalMission(payload?: {}): Promise<MissionTotalInterface> {
    const url = '/warehouse/accessories/get-total'
    const params = {
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      type: 'mission',
    }
    return baseAxios.get(url, { params })
  },

  getAllMission(payload?: {
    [key: string]: string
  }): Promise<MissionInterface> {
    const url = '/warehouse/mission/all'
    const { condition, pageSize, pageIndex } = getUrlFilterParams()
    const params = {
      limit: pageSize,
      offset: (pageIndex - 1) * pageSize || 0,
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      ...condition,
      ...payload,
    }
    return baseAxios.get(url, { params })
  },

  deleteMission(missionId: string): Promise<MissionDeleteInterface> {
    const url = '/warehouse/mission/delete'
    const params = { mission_id: missionId }
    return baseAxios.delete(url, { params })
  },

  updateMission(
    missionId: string,
    payload: MissionDetailPayloadInterface
  ): Promise<MissionResponse> {
    const url = `/warehouse/mission/${missionId}/update-mission`
    return baseAxios.patch(url, payload)
  },

  createCommentMission(
    payload: MissionCommentPayloadInterface
  ): Promise<ResponseGenerator> {
    const url = 'warehouse/mission/comment'
    return baseAxios.post(url, payload)
  },
}

export default missionApi
